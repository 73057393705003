import Styled from 'styled-components';

import { colors } from '../../styles/variables';

export const Wrapper = Styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: ${({ width }) => (width ? `${width}rem` : '100%')};
  height: ${({ height }) => (height ? `${height}rem` : '100%')};
  background: ${colors.darkTransparentShade};
`;

export const SpinnerImg = Styled.img`
  width: 4rem;
`;
